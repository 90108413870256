import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  async canActivate(): Promise<any> {
    const authUser = await this.auth.isAuthenticated();

    if (authUser) {
      this.router.navigate(['/']);
      return new Promise(resolve => resolve(false));
    }

    return new Promise(resolve => resolve(true));
  }
}
