export class PrivacyPolicy {
  id?: number;
  text?: string;

  constructor(privacyPolicy: any) {
    this.update(privacyPolicy);
  }

  update(privacyPolicy: any) {
    if (!privacyPolicy) {
      return;
    }

    if (privacyPolicy.id !== undefined) {
      this.id = privacyPolicy.id;
    }
    if (privacyPolicy.text !== undefined) {
      this.text = privacyPolicy.text;
    }
  }
}
