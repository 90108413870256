import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotifierModule } from 'angular-notifier';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { environment } from '@app/environments/environment';

import { GtagModule } from 'angular-gtag';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxSpinnerModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
        },
        vertical: {
          position: 'top',
        },
      },
      behaviour: {
        autoHide: 3000,
        showDismissButton: true,
      },
    }),
    GtagModule.forRoot({ trackingId: environment.gtagTrackingId, trackPageviews: true })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
