import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { HttpClientService } from '@app/services/httpClient.service';
import { User } from '@app/shared/models';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthService {
  currentUser: User;

  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private notifierService: NotifierService,
    private spinner: NgxSpinnerService,
    private firebaseAuth: AngularFireAuth,
  ) {}

  isAuthenticated() {
    return new Promise((resolve, reject) => {
      this.firebaseAuth.auth.onAuthStateChanged(authUser => {
        resolve(authUser);
      }, (error) => {
        reject(error);
      });
    });
  }

  async registerUser(formData: any) {

    const serializedFormData = JSON.stringify(formData);
    const createUser = await this.httpClientService.post('/users/register/', serializedFormData);

    if (createUser.success) {
      return true;
    }

    this.notifierService.notify('error', 'Failed to complete registration');

    return false;
  }

  async loginWithPassword(email: string, password: string) {
    this.spinner.show();

    try {
      await this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);

      return true;
    } catch (error) {
      this.notifierService.notify('error', error.message);

      return false;
    } finally {
      this.spinner.hide();
    }
  }

  async loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('profile');
    provider.addScope('email');

    try {
      await this.firebaseAuth.auth.signInWithPopup(provider);

      return true;
    } catch (error) {
      this.notifierService.notify('error', error.message);

      return false;
    }
  }

  async logout() {
    await this.invalidateToken();
    await this.firebaseAuth.auth.signOut();

    this.currentUser = null;
    this.router.navigate(['/login']);
  }

  async getCurrentProfile() {
    const loadProfileResult = await this.httpClientService.get('/users/me/');

    if (loadProfileResult.success) {
      this.currentUser = new User(loadProfileResult.data);

      if (!this.currentUser.hasRole) {
        this.notifierService.notify('warning', 'No role is assigned yet');
      }

      return true;
    }

    this.notifierService.notify('error', 'Failed to load user profile');

    return false;
  }

  private async invalidateToken() {
    const { currentUser } = this.firebaseAuth.auth;

    if (currentUser) {
      const { uid } = currentUser;
      await this.httpClientService.post('/users/logout/', { localId: uid });
    }
  }

  async requestPasswordReset(formData: any) {
    const serializedFormData = JSON.stringify(formData);
    const requestPwdReset = await this.httpClientService.post('/users/request_password_reset_email/', serializedFormData);
    
    if (requestPwdReset.success) {
      return true;
    }

    return false;
  }

  async resetPassword(newPassword: string, queryParams: any) {
    const serializedData = JSON.stringify({ newPassword, ...queryParams });
    const resetPwd = await this.httpClientService.post('/users/reset_password/', serializedData);

    return resetPwd;
  }
}
