import { Subject } from 'rxjs';

export class User {
  email?: string;
  groups?: string[];
  tanks?: number[];
  sites?: string[];
  policy?: number;
  terms?: number;

  policyAccepted: Subject<boolean> = new Subject<boolean>();
  termsAccepted: Subject<boolean> = new Subject<boolean>();

  get isAdmin() {
    if (!this.groups) {
      return false;
    }

    const adminRole = this.groups.find(group => group.toLowerCase().includes('admin'));
    return adminRole ? true : false;
  }

  get hasRole() {
    if (!this.groups || this.groups.length === 0) {
      return false;
    }

    return true;
  }

  constructor(user: any) {
    this.update(user);
  }

  update(user: any) {
    if (!user) {
      return;
    }

    if (user.email !== undefined) {
      this.email = user.email;
    }
    if (user.groups !== undefined) {
      this.groups = user.groups;
    }
    if (user.tanks !== undefined) {
      this.tanks = user.tanks;
    }
    if (user.sites !== undefined) {
      this.sites = user.sites;
    }
    if (user.policy !== undefined) {
      this.policy = user.policy;
    }
    if (user.terms !== undefined) {
      this.terms = user.terms;
    }
  }

  setAcceptedPolicy(id: number) {
    this.policy = id;
    this.policyAccepted.next(true);
  }

  setAcceptedTerms(id: number) {
    this.terms = id;
    this.termsAccepted.next(true);
  }
}
