import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/services/httpClient.service';
import { Order, Tank } from '@app/shared/models';

@Injectable()
export class OrderService {
  constructor(
    private httpClientService: HttpClientService,
  ) {}

  async makeOrder(order: Order, tank: Tank) {
    const orderResult = await this.httpClientService.post('/orders/', order);

    if (orderResult.success) {
      return new Order(orderResult.data);
    }

    return null;
  }
}
