import { Subject } from 'rxjs';
import { Order } from './order.model';
import { Vendor } from './vendor.model';
import { Site } from './site.model';

export class Tank {
  id?: number;
  name?: string;
  tank_id?: string;
  special_conditions?: string;
  max_volume?: number;
  current_volume?: number;
  alert_threshold?: number;
  replenish_lead_time?: number;
  last_reading?: string;
  last_update?: string;
  created_at?: string;
  chemical?: string;
  unit?: string;

  orders?: Order[];
  vendors?: Vendor[];
  site?: Site;
  mrp?: string;

  ordersUpdated: Subject<boolean> = new Subject<boolean>();

  get isBelowThreshold() { return this.current_volume <= this.alert_threshold; }
  get hasPendingOrder() { return this.orders.find(order => order.status === 'Requisition Pending'); }

  constructor(item: any, sites: any) {
    this.update(item, sites);
  }

  update(item: any, sites: any) {
    if (!item) {
      return;
    }
     const updateField = (fieldName: string, value: any) => {
        if (value !== undefined) {
            this[fieldName] = value;
        }
    };

    updateField('id', item.id);
    updateField('name', item.name);
    updateField('tank_id', item.tank_id);
    updateField('special_conditions', item.special_conditions);
    updateField('max_volume', item.max_volume);
    updateField('current_volume', item.current_volume ? item.current_volume : 0.0);
    updateField('alert_threshold', item.alert_threshold);
    updateField('replenish_lead_time', item.replenish_lead_time);
    updateField('last_reading', item.last_reading);
    updateField('last_update', item.last_update);
    updateField('created_at', item.created_at);
    updateField('chemical', item.chemical);
    updateField('unit', item.unit);
    updateField('mrp', item.mrp);



    if (Array.isArray(item.orders)) {
      this.orders = item.orders.map(order => new Order(order));
      this.orders.sort((prev, next) => {
        if (prev.order_date < next.order_date) {
          return 1;
        }

        return -1;
      });
    }

    if (Array.isArray(item.vendors)) {
      this.vendors = item.vendors.map(vendor => new Vendor(vendor));
    } else {
      this.vendors = [];
    }

    if (Array.isArray(sites)) {
      const siteData = sites.find(({ id }) => id === item.site);

      if (siteData) {
        this.site = new Site(siteData);
      }
    }


  }

  addOrder(order: Order) {
    if (this.orders) {
      this.orders = [order].concat(this.orders);
    } else {
      this.orders = [order];
    }

    this.ordersUpdated.next(true);
  }
}
