import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './guards/authGuard';
import { GuestGuard } from './guards/guestGuard';
import { ModalService } from './services/modal.service';
import { HttpClientService } from './services/httpClient.service';
import { PrivacyPolicyService } from './services/privacy.service';
import { TankService } from './services/tank.service';
import { TermsService } from './services/terms.service';
import { OrderService } from './services/order.service';
import { environment } from '@app/environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    GuestGuard,
    ModalService,
    HttpClientService,
    PrivacyPolicyService,
    TermsService,
    TankService,
    OrderService,
  ],
})
export class CoreModule { }
