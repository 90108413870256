import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import axios from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '@app/environments/environment';

export interface HttpClientResult {
  success: boolean;
  data: any;
}

@Injectable()
export class HttpClientService {
  constructor(
    private spinner: NgxSpinnerService,
    private firebaseAuth: AngularFireAuth,
  ) {
    axios.interceptors.request.use(
      async config => {
        if (this.firebaseAuth.auth.currentUser) {
          const idToken = await this.firebaseAuth.auth.currentUser.getIdToken();
          config.headers['Authorization'] = `FirebaseToken ${idToken}`;
        }

        config.headers['Content-Type'] = 'application/json';

        return config;
      },
      error => {
        Promise.reject(error);
      });
  }

  async post(url: string, data: any): Promise<HttpClientResult> {
    this.spinner.show();

    try {
      const result = await axios.request({
        baseURL: environment.api,
        url: url,
        method: 'POST',
        data,
      });

      return {
        success: true,
        data: result.data,
      };
    } catch (exception) {
      return {
        success: false,
        data: exception,
      };
    } finally {
      this.spinner.hide();
    }
  }

  async get(url: string, params: any = {}): Promise<HttpClientResult> {
    this.spinner.show();

    try {
      const result = await axios.request({
        baseURL: environment.api,
        url: url,
        method: 'GET',
        params,
      });

      return {
        success: true,
        data: result.data,
      };
    } catch (exception) {
      return {
        success: false,
        data: exception,
      };
    } finally {
      this.spinner.hide();
    }
  }
}
