import { Injectable } from '@angular/core';
import { HttpClientService } from './httpClient.service';
import { Terms } from '@app/shared/models';

@Injectable()
export class TermsService {
  constructor(
    private httpClientService: HttpClientService,
  ) {}

  currentTerms: Terms;

  async loadTerms() {
    const termsLoadResult = await this.httpClientService.get('/users/terms/');

    if (termsLoadResult.success) {
      this.currentTerms = new Terms(termsLoadResult.data);
      return this.currentTerms
    }

    return null;
  }

  async acceptTerms(id: number) {
    const termsAcceptResult = await this.httpClientService.post('/users/terms/', { id });

    if (termsAcceptResult.success) {
      return true;
    }

    return false;
  }
}
