export class Terms {
  id?: number;
  text?: string;

  constructor(terms: any) {
    this.update(terms);
  }

  update(terms: any) {
    if (!terms) {
      return;
    }

    if (terms.id !== undefined) {
      this.id = terms.id;
    }
    if (terms.text !== undefined) {
      this.text = terms.text;
    }
  }
}
