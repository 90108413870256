import { Injectable } from '@angular/core';
import { HttpClientService } from './httpClient.service';
import { PrivacyPolicy } from '@app/shared/models';

@Injectable()
export class PrivacyPolicyService {
  constructor(
    private httpClientService: HttpClientService,
  ) {}

  currentPrivacyPolicy: PrivacyPolicy;

  async loadPrivacyPolicy() {
    const policyLoadResult = await this.httpClientService.get('/users/policy/');

    if (policyLoadResult.success) {
      this.currentPrivacyPolicy = new PrivacyPolicy(policyLoadResult.data);
      return this.currentPrivacyPolicy;
    }

    return null;
  }

  async acceptPrivacyPolicy(id: number) {
    const policyAcceptResult = await this.httpClientService.post('/users/policy/', { id });

    if (policyAcceptResult.success) {
      return true;
    }

    return false;
  }
}
