export class Vendor {
  id?: number;
  name?: string;

  constructor(vendor: any) {
    this.update(vendor);
  }

  update(vendor: any) {
    if (!vendor) {
      return;
    }

    if (vendor.id !== undefined) {
      this.id = vendor.id;
    }
    if (vendor.vendor_name !== undefined) {
      this.name = vendor.vendor_name;
    }
  }
}
