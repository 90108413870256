import { Tank } from '@app/shared/models';
export class Order {
  id?: number;
  po_number?: string;
  tank?: number;
  site?: number;
  comments?: string;
  cost?: string;
  cost_currency?: string;
  estimated_delivery_date?: string;
  estimated_delivery_month?: string;
  material_code?: string;
  order_date?: string;
  order_month?: string;
  quantity?: number;
  requested_delivery_time_end?: string;
  requested_delivery_time_start?: string;
  status?: string;
  supplier?: number;
  unit?: string;

  get isPending() { return this.status === 'Requisition Pending'; }

  constructor(order: any) {

    this.update(order);
  }

  update(order: any) {
    if (!order) {
      return;
    }

    const DATE_FORMAT = 'en-US';
    const MONTH_OPTIONS = {
      month: 'short',
      timeZone: 'UTC'
    };

    const updateField = (field: string, value: any) => {
      if (value !== undefined) {
        this[field] = value;
      }
    };

    updateField('id', order.id);
    updateField('po_number', order.po_number);
    updateField('tank', order.tank);
    updateField('site', order.site);
    updateField('comments', order.comments);
    updateField('cost', order.cost);
    updateField('cost_currency', order.cost_currency);
    updateField('material_code', order.material_code);
    updateField('quantity', order.quantity);
    updateField('requested_delivery_time_end', order.requested_delivery_time_end);
    updateField('requested_delivery_time_start', order.requested_delivery_time_start);
    updateField('supplier', order.supplier);
    updateField('unit', order.unit);

    if (order.estimated_delivery_date !== undefined) {
      this.estimated_delivery_date = order.estimated_delivery_date;
      this.estimated_delivery_month = new Intl.DateTimeFormat(DATE_FORMAT, MONTH_OPTIONS).format( new Date(order.estimated_delivery_date) );
    }

    if (order.order_date !== undefined) {
      this.order_date = order.order_date;
      this.order_month = new Intl.DateTimeFormat(DATE_FORMAT, MONTH_OPTIONS).format( new Date(order.order_date) );
    }

    if (order.status !== undefined) {
      if (order.status === 'pending') {
        this.status = 'Requisition Pending';
      } else if (order.status === 'delivered') {
        this.status = 'Goods Receipt';
      } else {
        this.status = order.status;
      }
    }
  }
}
