import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
  ) {}

  async canActivate(): Promise<any> {
    const authUser = await this.auth.isAuthenticated();

    if (!authUser) {
      this.auth.logout();
      return new Promise(resolve => resolve(false));
    }

    if (this.auth.currentUser) {
      return new Promise(resolve => resolve(true));
    }

    return new Promise(resolve => {
      this.auth.getCurrentProfile()
        .then(res => {
          if (!res || !this.auth.currentUser.hasRole) {
            this.auth.logout();
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch(_ => {
          this.auth.logout();
          resolve(false);
        });
    });
  }
}
