import { Injectable } from '@angular/core';
import { HttpClientService } from './httpClient.service';
import { Tank } from '@app/shared/models';

@Injectable()
export class TankService {

  tanks: Tank[];
  sitesList: any;

  constructor(
    private httpClientService: HttpClientService
  ) { }

  async loadTanks() {

    const sitesList = await this.loadSitesList();
    
    if (this.tanks && this.tanks.length > 0) {
      return this.tanks;

    } else {
      const tanks = await this.httpClientService.get(`/tanks/`);
      if (tanks.success) {

        // Create new tank objects for each tank
        this.tanks = tanks.data.map(tank => new Tank(tank, sitesList));
        
        // Sort tanks by Site Name alphabetically
        this.tanks.sort((a, b) => (a.site.name > b.site.name) ? 1 : -1);
      }
      return this.tanks;
    }
  }

  async loadTankInfo(id: number) {

    const sitesList = await this.loadSitesList();

    if (this.tanks && this.tanks.length > 0) {
      return this.tanks.find(tank => tank.id == id);

    } else {
      const tankInfoLoadResult = await this.httpClientService.get(`/tanks/${id}/`);
      if (tankInfoLoadResult.success) {
        return new Tank(tankInfoLoadResult.data, sitesList);
      }
      return null;
    }
  }

  async loadSitesList() {
    if (this.sitesList && this.sitesList.length > 0) {
      return this.sitesList;

    } else {
      // Fetch the list of sites ids and names
      const sitesList = await this.httpClientService.get(`/sites/`);
      if (sitesList.success) {
        this.sitesList = sitesList.data;
        return this.sitesList;
      }
      return null;
    }
  }
}
