export class Site {
  id?: number;
  name?: string;

  constructor(site: any) {
    this.update(site);
  }

  update(site: any) {
    if (!site) {
      return;
    }

    if (site.id !== undefined) {
      this.id = site.id;
    }
    if (site.name !== undefined) {
      this.name = site.name;
    }
  }
}
